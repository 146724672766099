import React, { useMemo } from 'react'
import { graphql, Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import { ImageGallery } from '/src/components/gallery'
import { Accordion, SendEnquiryBlock } from '/src/components/common'
import { removeTags, trimMetaDescription } from '/src/util'

export const Head = ({ data }) => {
  const [product] = data.allNodeProduct.edges
  const hasBody = useMemo(() => product.node.body && product.node.body[0] && product.node.body[0].processed ? true : false, [product.node.body])

  return (
    <>
      <title>{product.node.title} | Orrcon Steel</title>
      {hasBody && <meta name="description" content={trimMetaDescription(removeTags(product.node.body[0].processed))} />}
      {hasBody && <meta name="og:description" content={trimMetaDescription(removeTags(product.node.body[0].processed))} />}
      <meta property="og:image" content="https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/og_orrcon.png" />
    </>
  )
}

export default ({ data }) => {
  const [product] = data.allNodeProduct.edges

  if (!product) {
    return 'no product information found.'
  }

  return (
    <Layout>
      <Row className="mb-5">
        <Col xs={12}>
          <ProductInfoView {...product.node} />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={12}>
          <SendEnquiryBlock />
        </Col>
      </Row>
    </Layout>
  )
}

const ProductInfoGalleryView = ({ field_gallery }) => {
  const images = useMemo(() => {
    return field_gallery.map(({ uri }) => {
      return {
        image: uri.url,
        content: null
      }
    })
  }, [])

  return <ImageGallery images={images} />
}

const ProductInfoRangeView = ({ field_range_info_accordion }) => {
  return (
    <div className="range-info">
      {field_range_info_accordion && (
        <Accordion items={field_range_info_accordion} />
      )}
    </div>
  )
}

const ProductInfoView = ({ 
  title, 
  body, 
  field_product_abbreviation, 
  field_product_accordion, 
  field_product_notes,
  relationships
}) => {
  const { field_product_finishes, field_product_range_info, field_gallery } = relationships
  const product_range_info = field_product_range_info.find((value) => {
    return value.hasOwnProperty('field_range_info_accordion')
  })

  return (
    <Row className="mt-5">
      <Col className="mt-2" xs={12}>
        <div className="mb-5">
          <Link className="back-to-link" to='/product-info'>
            <FontAwesomeIcon icon={faArrowLeft} />
            Back to product information
          </Link>
        </div>

        <Row>
          <Col sm={12} md={6}>
            <div className="product-title-container mb-5">
              <h1 className="title">{title}</h1>
            </div>

            {body && body[0] && (
              <div className="body mb-2" dangerouslySetInnerHTML={{
                __html: body[0].processed
              }}></div>
            )}

            {field_product_accordion && field_product_accordion.length ? (
              <Accordion items={field_product_accordion} />
            ) : <></>}

            {field_product_finishes.length ? (
              <div className="product-finishes mt-4">
                <label className="mb-2">Product Finishes & Coating</label>
                <ul>
                  {field_product_finishes.map((value, index) => (
                    <li key={index}>{value.title}</li>
                  ))}
                </ul>
              </div>
            ) : <></>}

            {field_product_notes && field_product_notes.processed && (
              <div className="product-notes mt-4">
                <label className="mb-2">Product Notes</label>
                <div className="notes" dangerouslySetInnerHTML={{
                  __html: field_product_notes.processed
                }}></div>
              </div>
            )}
          </Col>
          <Col sm={12} md={6}>
            {field_gallery && (
              <ProductInfoGalleryView field_gallery={field_gallery} />
            )}
          </Col>
        </Row>
        
        {product_range_info && (
          <Row className="mt-5">
            <Col sm={12}>
              <ProductInfoRangeView {...product_range_info} />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  )
}

export const query = graphql`
  query ($id: String!) {
    allNodeProduct(filter: {id: {eq: $id}}) {
      edges {
        node {
          title
          id
          path {
            alias
          }
          relationships {
            field_gallery {
              uri {
                url
              }
            }
            field_product_range_info {
              field_range_info_accordion {
                answer
                question
              }
            }
            field_product_finishes {
              id
              title
            }
            field_product_category {
              name
            }
          }
          body {
            processed
          }
          field_product_abbreviation {
            processed
          }
          field_product_accordion {
            answer
            question
          }
          field_product_notes {
            processed
          }
        }
      }
    }
  }
`
